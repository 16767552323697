import React, { useEffect } from 'react';
import { navigate } from 'gatsby';

const isProduction = process.env.GATSBY_ENVIRONMENT === 'production';
const prefix = isProduction ? 'paidy' : 'paidy-stg';
const isMobileBreadkpoint =
  typeof window !== 'undefined' ? window.innerWidth <= 600 : false;

export default function HandleDeeplink({ deeplink = '', redirect = '' }) {
  useEffect(() => {
    if (isMobileBreadkpoint && typeof window !== 'undefined') {
      window.location = `${prefix}://${deeplink}`;
    } else {
      navigate(redirect);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div></div>;
}
